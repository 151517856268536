.nav-img {
	width: 120px;
}

.nav-brand {
	font-weight: bold !important;
	color: white !important;
}

.logout-btn-nav {
	font-weight: bold !important;
	font-size: 1rem !important;
	/* background-color: rgba(0, 0, 0, 0.075) !important; */
	color: #fff !important;
}

.logout-btn-nav:hover {
	color: #ffffff80 !important;
}