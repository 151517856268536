.create-question-form {
	margin-top: 50px;
	background: #081220 !important;
	height: 100vh !important;
}

.create-question-text-input {
	width: 70% !important;
	margin: 30px 0 !important;
}

.create-option-field {
	margin: 5px 0 !important;
	width: 50% !important;
	margin-right: 30px !important;
}

.create-question-marks-field {
	margin: 10px 0 !important;
	margin-bottom: 50px !important;
}

.media-switch {
	margin: 20px 0;
}

.add-media-section {
	margin-bottom: 5%;
}

.MuiSwitch-colorSecondary-409.Mui-checked + .MuiSwitch-track-413 {
    background-color: #1799E1 !important;
}

.MuiSwitch-colorSecondary-409.Mui-checked {
    color: #1799E1 !important;
}