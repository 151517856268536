.question-display-box {
	border-radius: 10px;
	height: 100%;
	border: 1px solid #ccc;
	min-height: 50px;
	padding: 3%;
	margin: 20px 0;
}

.question-description {
	font-weight: bold;
	font-size: 1.1rem;
}

.question-options {
	margin-top: 30px;
}

.question-input {
	margin-top: 30px;
}

.question-media-div {
	margin-left: 30px !important;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.question-img {
	min-width: 400px;
	min-height: 200px;
	max-width: 400px;
	max-height: 300px;
}

.question-video {
	min-width: 400px;
	min-height: 200px;
	max-width: 400px;
	max-height: 300px;
}