.test-details-page {
	background-color: #081220;
	min-height: 100vh;
}

.test-details-page h1 {
	margin: 0;
	padding: 20px 0;
}

.test-info {
	margin-bottom: 30px;
	color: white;
}

.test-details-container {
	margin-top: 0 !important;
	background-color: "#081220";
	min-height: calc(100vh - 64px);
	padding: 0 7%;
}

.test-page-no-domains {
	text-align: center;
	padding: 5% 0;
}

.test-page-domain {
	color: white;
}

.test-page-domains-list {
	margin: 7% 5%;
}

.delete-test-btn {
	background-color: #FF2211 !important;
	font-weight: bold !important;
	width: 9rem;
	height: 3.5rem;
}

.publish-btn {
	background-color: #1799E1 !important;
	width: 9rem;
	height: 3.5rem;
	color: white !important;
	font-weight: bold !important;
}