/* .submission-summary .MuiAccordionSummary-content-348 {
	display: flex !important;
	align-items: center !important;
} */

.csv-download-btn {
	background-color: green !important;
	color: white !important;
	font-weight: bold !important;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 19rem;
	height: 3rem;
}

.responses-btn {
	background-color: #1799E1 !important;
}
