.create-test-page {
	background-color: #081220;
	min-height: 100vh;
}

.create-test-main {
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #252D3A;
	height: calc(100vh - 64px) !important;
	margin: 0 25% !important;
	padding: 2% 5%;
}

.create-test-form {
	width: 100%;
	margin-top: 4%;
}

.test-create-input {
	width: 100% !important;
	margin: 10px 0 !important;
}

.date-time-picker-section {
	margin: 20px 0 !important;
}

.create-test-btn-div {
	margin: 4% 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 815px) {
	.create-test-main {
		margin: 0 0 !important;
	}
}