.test-page {
	background-color: #f5f5f5;
	min-height: calc(100vh - 64px);
}

.test-container {
	padding-top: 2%;
	background-color: #081220;
	min-height: calc(100vh - 64px);
}

.test-timer-grid {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10%;
	padding: 20px 0 !important;
}

.test-time-remaining {
	position: sticky;
	top: 10px;
}

.student-questions-display {
	padding-top: 30px;
}

.submit-fab {
	position: fixed !important;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	bottom: 20px !important;
	left: 30px !important;
}

.cont-colo{
	background: #081220 !important;
}

.submit-test-button{
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
}