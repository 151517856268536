@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap");

.form-input {
  width: 100% !important;
  background-color: #081220 !important;
  color: #fff !important;
}

.light-text {
  color: rgb(173, 173, 173);
}

a {
  text-decoration: none !important;
}

.custom-action-btn {
  font-weight: bold !important;
  border-radius: 50px !important;
  padding: 10px 15px !important;
  /* padding-left: 50px !important;
  padding-right: 50px !important; */
}

::-moz-selection {
  background: #1799E1;
  color: #fff;
}

::selection {
  background: #1799E1;
  color: #fff;
}

.org-website-link:hover {
  cursor: pointer !important;
}

.profile-section-container .MuiAvatar-img {
  object-fit: contain !important
}

.profile-banner {
  /* margin-top: 50px; */
  max-height: 500px !important;
  border-radius: 12px;
}

.card-text-stud-dash {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}
