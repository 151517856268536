@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");

/* @import url("http://fonts.cdnfonts.com/css/8bit-wonder"); */

@font-face {
  font-family: '8bit-wonder';
  src: url('../8bit_wonder/8-BITWONDER.TTF');
}

.tex {
  font-family: '8bit-wonder', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #b8d8f8;
  margin-bottom: 50px !important;
}

.des {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 650;
  font-size: 22px;
  line-height: 170%;
  /* or 37px */

  letter-spacing: 0.02em;

  color: #f3f3f3;
}

/* .runner-canvas{
    width: 820px !important;
    height: 205px !important;
    margin-left: -20% !important;
} */

