@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 0.5rem;
  background: #121212;
}

/* Track */
::-webkit-scrollbar-track {
  background: #121212;
  border-radius: 0.5rem;
  z-index: -99;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1799E1;
  border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1799E1;
  visibility: visible;
}
.form-input {
  width: 100% !important;
  background-color: #081220 !important;
  color: #fff !important;
}

.light-text {
  color: rgb(173, 173, 173);
}

a {
  text-decoration: none !important;
}

.custom-action-btn {
  font-weight: bold !important;
  border-radius: 50px !important;
  padding: 10px 15px !important;
  /* padding-left: 50px !important;
  padding-right: 50px !important; */
}

::selection {
  background: #1799E1;
  color: #fff;
}

.org-website-link:hover {
  cursor: pointer !important;
}

.profile-section-container .MuiAvatar-img {
  object-fit: contain !important
}

.profile-banner {
  /* margin-top: 50px; */
  max-height: 500px !important;
  border-radius: 12px;
}

.card-text-stud-dash {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

/* Button taken from: https://codepen.io/jimjammc/pen/PMOVZB */

.btn-grid {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	margin: 5% 0;
}

.google-link {
	margin-top: 4%;
}

.google-btn {
	width: 184px;
	height: 42px;
	background-color: #4285f4;
	border-radius: 2px;
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
}

.google-icon-wrapper {
	position: absolute;
	margin-top: 1px;
	margin-left: 1px;
	width: 40px;
	height: 40px;
	border-radius: 2px;
	background-color: white;
}

.google-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 18px;
	height: 18px;
}

.google-btn .btn-text {
	float: right;
	margin: 13px 10px 0 10px;
	color: white;
	font-size: 14px;
	letter-spacing: 0.2px;
	font-weight: bold;
}

.google-btn:hover {
	box-shadow: 0 0 6px #4285f4;
}

.google-btn:active {
	background: #1669f2;
}
.tile {
	margin: 0 1px;
	background-color: #1799E1;
	color: white;
	height: 65px;
	width: 65px;
	border-radius: 10px !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-left: 6px;
	margin-right: 6px;
}

.tile > .time {
	width: 100%;
	font-size: 1.4rem;
	font-weight: 500;
}

.tile > .type {
	width: 100%;
	display: block;
	font-size: 0.6rem;
	font-weight: 600;
	text-transform: uppercase;
}
.countdown-modal {
	/* display: flex;
	justify-content: center;
	align-items: center; */
	text-align: center !important;
	border-radius: 16px !important;
}

.close-modal {
	background-color: #107be5 !important;
	color: white !important;
	font-weight: bold !important;
	padding: 8px 20px !important;
	border-radius: 4px !important;
	margin: 60px;
	font-size: 12px !important;
}

.button-div{
	margin: 10px 10px 15px 20px;
}
.question-display-box {
	border-radius: 10px;
	height: 100%;
	border: 1px solid #ccc;
	min-height: 50px;
	padding: 3%;
	margin: 20px 0;
}

.question-description {
	font-weight: bold;
	font-size: 1.1rem;
}

.question-options {
	margin-top: 30px;
}

.question-input {
	margin-top: 30px;
}

.question-media-div {
	margin-left: 30px !important;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.question-img {
	min-width: 400px;
	min-height: 200px;
	max-width: 400px;
	max-height: 300px;
}

.question-video {
	min-width: 400px;
	min-height: 200px;
	max-width: 400px;
	max-height: 300px;
}
.test-page {
	background-color: #f5f5f5;
	min-height: calc(100vh - 64px);
}

.test-container {
	padding-top: 2%;
	background-color: #081220;
	min-height: calc(100vh - 64px);
}

.test-timer-grid {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10%;
	padding: 20px 0 !important;
}

.test-time-remaining {
	position: -webkit-sticky;
	position: sticky;
	top: 10px;
}

.student-questions-display {
	padding-top: 30px;
}

.submit-fab {
	position: fixed !important;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	bottom: 20px !important;
	left: 30px !important;
}

.cont-colo{
	background: #081220 !important;
}

.submit-test-button{
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.nav-img {
	width: 120px;
}

.nav-brand {
	font-weight: bold !important;
	color: white !important;
}

.logout-btn-nav {
	font-weight: bold !important;
	font-size: 1rem !important;
	/* background-color: rgba(0, 0, 0, 0.075) !important; */
	color: #fff !important;
}

.logout-btn-nav:hover {
	color: #ffffff80 !important;
}
.club-test-tile {
	width: 170px;
	height: 170px;
	border-radius: 10px;
	background: linear-gradient(180deg, #1799E1 0%, #093966 100%);
	color: white;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transition: all 0.2s ease-in-out;
	text-align: center;
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
	box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.20);
}

.club-test-tile:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
	cursor: pointer;
}

.tile-subtitle{
	text-align: center;
}

.tile-subtitle{
	color: white;
	opacity: 0.8;
	padding: 0.5em;

}
.student-test-list {
	padding: 3%;
	background-color: #081220 !important;
}

.modal-test-instructions {
	background-color: #081220;
	color: #777;
	padding: 20px 10px;
	border-radius: 5px;
	margin-bottom: 10px;
	white-space: pre-wrap;
	max-height: 250px !important;
	overflow: scroll;
}

.club-dash-tests{
	height: 100%;
	background: #081220 !important;
}

.cont-col{
	height: 100vh;
	overflow-y: hidden;
	background: #081220 !important;
}
.club-domain-tile {
	width: 200px;
	height: 200px;
	border-radius: 10px;
	background: linear-gradient(180deg, #1799E1 0%, #093966 100%) !important;
	color: "081220";
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
	box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.20);
}

.tile-title {
	font-size: 1.4rem;
	text-align: center;
	color: white;
}

.club-domain-tile:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
	cursor: pointer;
}
.create-test-page {
	background-color: #081220;
	min-height: 100vh;
}

.create-test-main {
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #252D3A;
	height: calc(100vh - 64px) !important;
	margin: 0 25% !important;
	padding: 2% 5%;
}

.create-test-form {
	width: 100%;
	margin-top: 4%;
}

.test-create-input {
	width: 100% !important;
	margin: 10px 0 !important;
}

.date-time-picker-section {
	margin: 20px 0 !important;
}

.create-test-btn-div {
	margin: 4% 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 815px) {
	.create-test-main {
		margin: 0 0 !important;
	}
}
.dashboard {
  height: 100vh;
  overflow-x: hidden;
  background: #081220;
}

.dashboard-drawer {
  width: 240px !important;
  background: #081220 !important;
}

.dash-main {
  margin-left: 70px;
  height: calc(100vh - 64px);
}

.sidebar-item {
  padding: 20px 16px !important;
  color: #fff !important;;
  /* background-color: #081220 !important; */
}

.MuiDrawer-paper{
  background: #081220 !important;
  border-right: 1px solid #F5F5F540 !important;
}

.dashboard-drawer .MuiPaper-root-28{
  background: #081220 !important;
  border-right: 1px solid #F5F5F540 !important;
}
.profile-modal {
	padding: 3% !important;
}

.dropzone {
	background-color: rgb(187, 187, 187);
	padding: 1%;
	padding-top: 5%;
	text-align: center;
	border-radius: 5px;
}

.drop-icon {
	color: rgb(110, 110, 110) !important;
	font-size: 7rem !important;
}
.club-dash-tests {
	height: 100%;
	background: #081220 !important;
}

.club-dash-test-bar {
	height: 10%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.club-test-list {
	height: calc(100vh - 64px - 10%);
}

.no-tests-div {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.club-tests-display {
	padding: 3% 5%;
}
.MuiInputBase-root {
    background-color: rgba(255, 255, 255, 0.342);
}
.test-details-page {
	background-color: #081220;
	min-height: 100vh;
}

.test-details-page h1 {
	margin: 0;
	padding: 20px 0;
}

.test-info {
	margin-bottom: 30px;
	color: white;
}

.test-details-container {
	margin-top: 0 !important;
	background-color: "#081220";
	min-height: calc(100vh - 64px);
	padding: 0 7%;
}

.test-page-no-domains {
	text-align: center;
	padding: 5% 0;
}

.test-page-domain {
	color: white;
}

.test-page-domains-list {
	margin: 7% 5%;
}

.delete-test-btn {
	background-color: #FF2211 !important;
	font-weight: bold !important;
	width: 9rem;
	height: 3.5rem;
}

.publish-btn {
	background-color: #1799E1 !important;
	width: 9rem;
	height: 3.5rem;
	color: white !important;
	font-weight: bold !important;
}
.create-question-form {
	margin-top: 50px;
	background: #081220 !important;
	height: 100vh !important;
}

.create-question-text-input {
	width: 70% !important;
	margin: 30px 0 !important;
}

.create-option-field {
	margin: 5px 0 !important;
	width: 50% !important;
	margin-right: 30px !important;
}

.create-question-marks-field {
	margin: 10px 0 !important;
	margin-bottom: 50px !important;
}

.media-switch {
	margin: 20px 0;
}

.add-media-section {
	margin-bottom: 5%;
}

.MuiSwitch-colorSecondary-409.Mui-checked + .MuiSwitch-track-413 {
    background-color: #1799E1 !important;
}

.MuiSwitch-colorSecondary-409.Mui-checked {
    color: #1799E1 !important;
}
.domain-details-page {
	background-color: #081220;
	height: 100% !important;
	overflow: hidden;
}

.domain-details-page h1 {
	margin-top: 0;
	padding-top: 2%;
}

.dialog-top-btn {
	font-weight: bold !important;
	font-size: 1.1rem !important;
}

.add-question-drawer-item {
	padding: 15px 30px !important;
}

.create-question-area {
	padding-left: 300px;
	padding-top: 80px;
	overflow: auto;
}

.questions-loading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100px;
}

.domain-questions {
	margin: 5% 0;
}

.test-page-domain {
	padding-bottom: 3%;
}

.delete-domain-btn {
	font-weight: bold !important;
	background-color: #FF2211  !important;
	width: 10rem;
	height: 3.5rem;

}

.MuiTypography-displayBlock  {
	color: white !important;
}


/* .submission-summary .MuiAccordionSummary-content-348 {
	display: flex !important;
	align-items: center !important;
} */

.csv-download-btn {
	background-color: green !important;
	color: white !important;
	font-weight: bold !important;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 19rem;
	height: 3rem;
}

.responses-btn {
	background-color: #1799E1 !important;
}

.MuiInputBase-input.Mui-disabled {
    color: #24262A;
}

.MuiFormLabel-root.Mui-disable {
    color: #24262A;
}
/* @import url("http://fonts.cdnfonts.com/css/8bit-wonder"); */

@font-face {
  font-family: '8bit-wonder';
  src: url(/static/media/8-BITWONDER.5fa66681.TTF);
}

.tex {
  font-family: '8bit-wonder', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #b8d8f8;
  margin-bottom: 50px !important;
}

.des {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 650;
  font-size: 22px;
  line-height: 170%;
  /* or 37px */

  letter-spacing: 0.02em;

  color: #f3f3f3;
}

/* .runner-canvas{
    width: 820px !important;
    height: 205px !important;
    margin-left: -20% !important;
} */


