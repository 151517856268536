/* Button taken from: https://codepen.io/jimjammc/pen/PMOVZB */

.btn-grid {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	margin: 5% 0;
}

.google-link {
	margin-top: 4%;
}

.google-btn {
	width: 184px;
	height: 42px;
	background-color: #4285f4;
	border-radius: 2px;
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
}

.google-icon-wrapper {
	position: absolute;
	margin-top: 1px;
	margin-left: 1px;
	width: 40px;
	height: 40px;
	border-radius: 2px;
	background-color: white;
}

.google-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 18px;
	height: 18px;
}

.google-btn .btn-text {
	float: right;
	margin: 13px 10px 0 10px;
	color: white;
	font-size: 14px;
	letter-spacing: 0.2px;
	font-weight: bold;
}

.google-btn:hover {
	box-shadow: 0 0 6px #4285f4;
}

.google-btn:active {
	background: #1669f2;
}