.domain-details-page {
	background-color: #081220;
	height: 100% !important;
	overflow: hidden;
}

.domain-details-page h1 {
	margin-top: 0;
	padding-top: 2%;
}

.dialog-top-btn {
	font-weight: bold !important;
	font-size: 1.1rem !important;
}

.add-question-drawer-item {
	padding: 15px 30px !important;
}

.create-question-area {
	padding-left: 300px;
	padding-top: 80px;
	overflow: auto;
}

.questions-loading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100px;
}

.domain-questions {
	margin: 5% 0;
}

.test-page-domain {
	padding-bottom: 3%;
}

.delete-domain-btn {
	font-weight: bold !important;
	background-color: #FF2211  !important;
	width: 10rem;
	height: 3.5rem;

}

.MuiTypography-displayBlock  {
	color: white !important;
}
